<template>
  <div class='sidepanel-horizontal-flush relative'>
    <div class='pt-2 sticky top-0 left-0 bg-white z-10'>
      <h1 class='mt-0 lg:mt-4 text-xl font-semibold uppercase px-2 lg:px-8'>
        {{ portfolioEntityName }}
      </h1>
      <div class='border-b mt-4 lg:mt-8 pl-2 lg:pl-8 flex flex-row justify-start text-xs lg:text-sm text-gray-600 overflow-x-auto'>
        <div v-for='tab in tabs'
          :key='`tab-${tab.name}`'
          class='uppercase tracking-wide px-4 lg:px-8 pb-2 pt-4 cursor-pointer hover:bg-gray-100'
          :class='selectedTab(tab.name)'
          @click='setActiveTab(tab.name)'>
          {{ tab.title }}
        </div>
      </div>
    </div>
    <component 
      :is='tabContentComponent'
      @done-editing='doneEditing' />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PortfolioDetailsMobile',
  components: {
    'portfolio-information': () => import('@/views/portfolio/PortfolioInformation.vue'),
    'investment-files-mobile': () => import('@/components/mobile/InvestmentFilesMobile.vue'),
    'investment-history': () => import('@/components/mobile/InvestmentHistoryMobile.vue'),
  },
  data () {
    return {
      activeTab: 'portfolio-information',
    }
  },
  computed: {
    ...mapGetters('portfolios', [
      'portfolioEntityName',
    ]),
    tabs () {
      return [
        {
          name: 'portfolio-information',
          title: 'Info',
        },
        {
          name: 'investment-files-mobile',
          title: 'Files',
        },
        {
          name: 'investment-history',
          title: 'Updates',
        }
      ]
    },
    tabContentComponent () {
      return this.activeTab
    },
  },
  methods: {
    ...mapActions(['sidepanelClose']),
    doneEditing () {
      this.sidepanelClose()
    },
    setActiveTab (tab) {
      this.activeTab = tab
    },
    selectedTab (tab) {
      return (this.activeTab === tab) ? 'selected' : ''
    },
  },
  beforeDestory () {
    this.sidepanelClose()
  },
}
</script>

<style lang='scss' scoped>
  .selected {
    @apply font-semibold;
    color: #0D4C76;
    border-bottom: solid 2px #0D4C76;
  }
</style>
