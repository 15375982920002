import { render, staticRenderFns } from "./PortfolioDetailsMobile.vue?vue&type=template&id=ab37aa32&scoped=true"
import script from "./PortfolioDetailsMobile.vue?vue&type=script&lang=js"
export * from "./PortfolioDetailsMobile.vue?vue&type=script&lang=js"
import style0 from "./PortfolioDetailsMobile.vue?vue&type=style&index=0&id=ab37aa32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab37aa32",
  null
  
)

export default component.exports